import React from "react";
import { Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";

import { Card, Space } from "./framework/Card";
import { Title } from "./framework/Title";

import banking from "../image/Solutions/Consulting/BankingApplications.jpg";
import business from "../image/Solutions/Consulting/BusinessProcessManagementTools.jpg";
import EnterpriseArchitectureConsulting from "../image/Solutions/Consulting/EnterpriseArchitectureConsulting.jpg";
import InsuranceApplications from "../image/Solutions/Consulting/InsuranceApplications.jpg";
import ProjectManagement from "../image/Solutions/Consulting/ProjectManagement.jpg";
import mainframe from "../image/Solutions/EnterpriseSoftwareSolutions/mainframe.jpg";
import RPA from "../image/Solutions/EnterpriseSoftwareSolutions/RPA.jpg";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("consulting");
}

function Consulting() {
  initGA();
  return (
    <Container
      fluid
      style={{
        marginTop: "100px",
        marginBottom: "50px",
        //minHeight: "100vh",
        minHeight: "100vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Title>Consulting</Title>
      <br />
      <Row fluid>
        <Card title="Banking Applications" src={banking}></Card>
        <Card title="Business Process Management Tools" src={business}></Card>
        <Card
          title="Enterprise Architecture Consulting"
          src={EnterpriseArchitectureConsulting}
        ></Card>
        <Card title="Insurance Applications" src={InsuranceApplications}></Card>
        <Card title="Test Automation Tools" src={RPA}></Card>
        <Card title="Mainframe Migration and Conversion" src={mainframe}></Card>
        <Space />
        <Card title="Project Management" src={ProjectManagement}></Card>
        <Space />
      </Row>
    </Container>
  );
}

export default Consulting;
