import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactGA from "react-ga";

import training from "../image/Solutions/Training/Training.jpg";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("training");
}

function Training() {
  initGA();
  return (
    <Container
      fluid
      style={{
        marginTop: "100px",
        marginBottom: "50px",
        minHeight: "100vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Row style={{ textAlign: "center" }}>
        <Col xs={0} md={4} />
        <Col xs={12} md={4}>
          <h2
            style={{
              padding: "5px",
              borderBottom: "2px solid #c40000",
            }}
          >
            Training
          </h2>

          <p style={{ fontSize: "0.9rem" }}>
            We provide the following trainings both in Turkish and English
          </p>
          <br />
          <Image fluid src={training} />
          <br />
          <br />
          <ul style={{ textAlign: "left" }}>
            <li>TOGAF Foundation</li>
            <li>PEGA PRPC</li>
            <li>Enterprise Design Patterns &amp; Architectures</li>
            <li>Project Management</li>
            <li>Test Management&nbsp;</li>
          </ul>
        </Col>
        <Col xs={0} md={4} />
      </Row>
    </Container>
  );
}

export default Training;
