import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import whoarewe from "../../../image/Home/whoarewe.jpg";

export const About = () => (
  <div style={{ background: "#efefef", paddingTop: "30px" }}>
    <Container fluid style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
      <Row>
        <Col xs={12} md={5}>
          <Image fluid src={whoarewe} style={{ borderRadius: "5px" }} />
        </Col>
        <Col
          xs={12}
          md={7}
          style={{
            display: "flex",
            alignItems: "middle",
          }}
        >
          <span style={{ margin: "auto" }}>
            <br />
            <h2>Who Are We?</h2>
            We are a leading research – development and consulting company that
            has been working with a variety of customers since 2013. Our team
            consists of information technologies and services professionals, who
            have over 25 years of experience in the industry. Our aim is to
            prioritise the demands and needs of our customers and provide the
            best solution-based services.
          </span>
        </Col>
      </Row>
    </Container>
  </div>
);
