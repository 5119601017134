import React from "react";
import { Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";

import { Card, Space } from "./framework/Card";
import { Title } from "./framework/Title";

import AML from "../image/Products/BE1AML.jpg";
import converter from "../image/Products/BE1Converter.jpg";
import restaurant from "../image/Products/Intelligentrestaurant.jpg";
import nfc from "../image/Products/nfc.jpg";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("products");
}

function Products() {
  initGA();
  return (
    <Container
      fluid
      style={{
        marginTop: "100px",
        marginBottom: "50px",
        minHeight: "100vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Title>Products</Title>
      <Row fluid>
        {/*KOSGEB için kaldırıldı. */}
        {/*<Card title="Intelligent Restaurant" src={restaurant}>
          An ongoing research and development project lead by BE1 Consultancy.
          The aim of the project is to create a cloud-based restaurant system
          where voice inputs will be utilised to place orders on the system. The
          inputs also be stored in a database to create financial reports for
          the system. Several artificial intelligence and machine learning
          techniques are also used to enhance the user experience.
        </Card>*/}
        <Card title="NFCRead" src={nfc}>
          NFCRead is a product that has been developed together by BE1
          Consultancy and Sanalogi Inc. In face-to-face transactions, NFCRead is
          used by employees to read and verify the identity document of someone
          in front of them; For example, an employee who checks the identity of
          someone on the street can use it anywhere, using iOS or Android phones
          that meet the requirements without expensive and detailed special
          hardware. It does not require training and provides a perfect entry of
          personal information. For more information, please visit:{" "}
          <a
            href="https://nfcread.com/en/main"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.nfcread.com
          </a>
        </Card>
        <Card title="BE1 Converter" src={converter}>
          A language transliteration product that can convert other software
          products from one framework (java,XML,RPG) to the other
          (ASP.net,MVC,Java Struts)
        </Card>
       {/* <Space /> */}
        <Card title="BE1 AML" src={AML}>
          A product designed to be integrated to the KYC processes of the
          finance and the insurance companies, that is capable of processing
          money laundering lists by using smart algorithms.
        </Card>
      </Row>
    </Container>
  );
}

export default Products;
