import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactGA from "react-ga";



function initGA() {
    ReactGA.initialize("UA-55920635-1");
    ReactGA.pageview("kvkk");
  }

function Kvkk() {
    initGA();
  return (
<Container
      fluid
      style={{
        marginTop: "100px",
        marginBottom: "50px",
        minHeight: "100vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
<Row style={{ textAlign: "center" }}>
        <Col xs={0} md={4} />
        <Col xs={12} md={4}>
          <h2
            style={{
              padding: "5px",
              borderBottom: "2px solid #c40000",
            }}
          >
           BE1 YAZILIM DANIŞMANLIK VE TİCARET ANONİM ŞİRKETİ
KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİNE İLİŞKİN ÇALIŞAN ADAYI AYDINLATMA METNİ

          </h2>

          <p style={{ fontSize: "0.9rem" }}>
          Tarafımıza yaptığınız iş başvurunuzun değerlendirilmesi sürecinde işlenecek kişisel verilerinize ilişkin olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun“) uyarınca “Veri Sorumlusu” sıfatıyla BE1 Yazılım Danışmanlık ve Ticaret Anonim Şirketi(“BE1Consultancy”) olarak sizleri bilgilendirmek istiyoruz.
          </p>
          <br />
          <h2
            style={{
              padding: "5px",
              borderBottom: "2px solid #c40000",
            }}
          >
           Kişisel Verilerinizi İşleme Amaçlarımız ve İşlenecek Kişisel Verileriniz Nelerdir?

          </h2>
          <p style={{ fontSize: "0.9rem" }}>
          Başvurduğunuz pozisyon için değerlendirilebilmeniz ve gerektiğinde sizinle tekrar iletişime geçilebilmesi amacıyla 
          </p>
          <br />
          <br />
          <ul style={{ textAlign: "left" }}>
            <li>Kimlik bilgileriniz (adınız, soyadınız, yaşınız),</li>
            <li>İletişim bilgileriniz (E-posta adresiniz, telefonu numaranız, il ve ilçe olarak adresiniz), </li>
            <li>mesleki deneyim bilgileriniz, </li>
            <li>Eğitim bilgileriniz, </li>
            <li>Yabancı dil yeterlilikleriniz, </li>
            <li>Aldığınız sertifikalar, kurslar ve diğer beceriler ve yeterlilikleriniz, </li>
            <li>Katıldığınız sertifika programları (kurs, seminer vb., diğer ödül ve takdirler), </li>
            <li>Askerlik durumunuz, </li>
            <li>Özgeçmişinizde yer alan diğer bilgiler,</li>
            <li>Özlük bilgileriniz (daha önce çalıştığınız iş yerleri, pozisyonunuz, deneyimleriniz, çalışma tarihleri, ayrılma nedeniniz),</li>
            <li>Referans bilgileriniz,</li>
            <li>Ücret beklentiniz, </li>
            <li>Başvurulan pozisyona yönelik mülakat sorularına verdiğiniz yanıtlar, </li>
            <li>Görüşme notları</li>
            <p style={{ fontSize: "0.9rem" }}>
            <br />
            bilgileriniz işlenecektir.
          </p>
          <br />
          <p style={{ fontSize: "0.9rem" }}>
          İşe ve pozisyona uygunluğunuzun değerlendirilmesi için yeterli olacak kişisel verileriniz dışındaki verilerinizin toplanmaması esastır. Bu nedenle BE1Consultancy’e yapacağınız başvurularda ve paylaşacağınız özgeçmişlerde yukarıda belirtilenler ve pozisyona uygunluğunuz için gerekli olanın dışındaki kişisel verilerinizi ve özellikle özel nitelikli kişisel verilerinizi (sağlık bilgileri, üye olunan dernek, vakıf, sendika üyeliği vs. gibi) paylaşmamanızı öneriyoruz. Bizimle paylaşacağınız referanslarınıza ait kişisel verilerle (ad soyad, çalıştığı kurum, görevi, telefon numarası) ilgili olarak referanslarınızın bilgilendirilmeleri ve gerektiği takdirde açık rızalarının alınmasına ilişkin sorumluluk tarafınıza aittir.
          </p>

          <h2
            style={{
              padding: "5px",
              borderBottom: "2px solid #c40000",
            }}
          >
           Kişisel Verilerinizi Kimlerle ve Hangi Amaçla Paylaşabiliriz?

          </h2>
          <p style={{ fontSize: "0.9rem" }}>
          Kişisel verileriniz, bilişim teknolojileri veya uzmanlık gerektiren danışmanlık, ürün veya hizmet tedariki konularında destek aldığımız yurt içi ve yurtdışındaki tedarikçilerimizle, ayrıca görevli kamu kurum ve şirketleri ile adli makamlara karşı olan bilgi, belge verme ve ilgili sair yükümlülüklerimizi yerine getirmek ve dava ve cevap hakları gibi yasal haklarımızı/haklarınızı kullanabilmek amacıyla ilgili kurumlarla paylaşılmaktadır.
          </p>
          <h2
            style={{
              padding: "5px",
              borderBottom: "2px solid #c40000",
            }}
          >
           Kişisel verilerinizin toplanma yöntemi ve hukuki nedeni?

          </h2>
          <p style={{ fontSize: "0.9rem" }}>
          Kişisel verilerinizi; e-posta ile yapacağınız başvuruların sistemlerimize kaydedilmesi ile, kişisel verilerinizi alenileştirdiğiniz kariyer platformlarında paylaştığınız kişisel verilerinizin sistemlerimize kaydedilmesi ile, bizimle paylaşacağınız referanslarla yaptığımız görüşmelerden alınan bilgilerin dijital ortamlara kaydedilmesi ile otomatik yollarla elde edilmektedir.
          </p>
          <br />
          <p style={{ fontSize: "0.9rem" }}>
          Yukarıda belirtilen yöntemlerle iş başvuru sürecinize ilişkin kişisel verileriniz “sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması”, “ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” ve “ilgili kişinin kendisi tarafından alenileştirilmiş olması” hukuki sebeplerine dayanarak işlenmektedir.
          </p>
          <h2
            style={{
              padding: "5px",
              borderBottom: "2px solid #c40000",
            }}
          >
           Haklarınız nelerdir?

          </h2>
          <p style={{ fontSize: "0.9rem" }}>
          Kanun’un 11. maddesi uyarınca, kişisel verileri işlenen çalışan adayı, aşağıdaki konularda hak sahibidir ; 
          </p>
            <li>Kişisel verilerinin işlenip işlenmediğini öğrenme,</li>
            <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme, </li>
            <li>Kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, </li>
            <li>Kişisel verilerinin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri öğrenme,</li>
            <li>Kişisel verilerinin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>Kişisel verilerinin işlenmesini gerektiren sebeplerin ortadan kalkması halinde bunların silinmesini, yok edilmesini veya anonim hale getirilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>İşlenen verilerinin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle veri sahibinin aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
            <li>Kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme.</li>

            <p style={{ fontSize: "0.9rem" }}>
            Bu haklarınıza ilişkin taleplerinizi BE1Consultancy’ nin Esentepe Mah. Büyükdere Cd. Ferko Signature Blok, Plaza Cubes, No:175 / 141 34394 Levent, Şişli / İSTANBUL adresine yazılı başvuru yaparak veya daha önce bildirdiğiniz ve sistemimizde kayıtlı bulanan e-posta adresinizden, BE1Consultancy’nin basvuru@be1consultancy.com adresine  e-posta göndererek iletebilirsiniz.
          </p>
          <br />
          <p style={{ fontSize: "0.9rem" }}>
          Çalışan Adayı, işbu Aydınlatma Metni’ne konu kişisel verilerinin tam, doğru ve güncel olduğunu taahhüt etmektedir. Kişisel verilerine ilişkin herhangi bir değişikliğin söz konusu olması halinde, BE1CONSULTANCY ’i bilgilendirerek kişisel verilerinin güncellenmesini talep edebilecektir. Çalışan Adayı’nın doğru ve güncel bilgi sağlamamış olması halinde, BE1CONSULTANCY ’in hiçbir sorumluluğu olmayacaktır.
          </p>
          <br />
          <p style={{ fontSize: "0.9rem" }}>
          İşbu Aydınlatma Metni, gerekli görüldüğü hallerde BE1CONSULTANCY tarafından revize edilebilir. Revizyonun söz konusu olduğu hallerde ise, bu hususa ilişkin olarak çalışan adayına bilgilendirme yapılacaktır. 
          </p>
          </ul>
        </Col>
        <Col xs={0} md={4} />
      </Row>
</Container>
  );
}
export default Kvkk;
