import React from "react";
import { Col, Image } from "react-bootstrap";

export const Card = (props) => (
  <Col xs={12} md={4} style={{ paddingBottom: "15px" }}>
    <div
      style={{
        backgroundColor: "#efefef",
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      <Image fluid src={props.src} style={{ borderRadius: "5px" }} />
      <h4 style={{ textAlign: "center" }}>{props.title}</h4>
      <p>{props.children}</p>
    </div>
  </Col>
);

export const Space = () => <Col xs={0} md={4} />;

export const SpaceHalf = () => <Col xs={0} md={2} />;
