import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
//import work from "../../../image/work.jpg";
import ourwork from "../../../image/Home/ourwork.jpg";

export const Work = () => (
  <div style={{ background: "#efefef", paddingBottom: "30px" }}>
    <br />
    <br />
    <Container fluid style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
      <Row>
        <Col xs={12} md={5}>
          <Image fluid src={ourwork} style={{ borderRadius: "5px" }} />
        </Col>
        <Col
          xs={12}
          md={7}
          style={{
            display: "flex",
            alignItems: "middle",
          }}
        >
          <span style={{ margin: "auto" }}>
            <br />
            <h2>Our Work</h2>
            As BE1 Consultancy Team, we focus on developing sector based
            full-stack solutions, solving problems by utilising machine learning
            techniques (speech-to-text, image processing, IOT) and application
            modernisation.
          </span>
        </Col>
      </Row>
    </Container>
  </div>
);
