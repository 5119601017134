import React from "react";
import { Nav, Navbar, Image, NavDropdown } from "react-bootstrap";
import "./NavBar.css";
import logo from "../../image/logo.png";
export const NavBar = () => (
  <Navbar fixed="top" collapseOnSelect expand="md" variant="light" bg="light">
    <Navbar.Brand href="/">
      <Image fluid className="logo" src={logo} />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto">
        <Nav.Item>
          <Nav.Link href="/about">About</Nav.Link>
        </Nav.Item>
        <NavDropdown title="Solutions" id="basic-nav-dropdown">
          <NavDropdown.Item href="/ess">
            Enterprise Software Solutions
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/consulting">Consulting</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/training">Training</NavDropdown.Item>
        </NavDropdown>
        <Nav.Item>
          <Nav.Link href="/products">Products</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/references">References</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav.Item>
      
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);
