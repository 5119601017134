import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
//import solutions from "../../../image/solutions.png";
import whatwedo from "../../../image/Home/whatwedo.jpg";

export const Solutions = () => (
  <div style={{ background: "#efefef" }}>
    <br />
    <br />
    <Container fluid style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 7, order: 1 }}
          style={{
            display: "flex",
            alignItems: "middle",
          }}
        >
          <span style={{ margin: "auto" }}>
            <br />
            <h2>What We Do</h2>
            We provide solutions in various projects from different industries,
            ranging from banking to manufacturing. The experience of our team
            allows us to undertake complicated tasks such as business
            consulting, architectural design, software development, project
            management, system testing and administration.
          </span>
        </Col>
        <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
          <Image fluid src={whatwedo} style={{ borderRadius: "5px" }} />
        </Col>
      </Row>
    </Container>
  </div>
);
