import React from "react";
import { Container } from "react-bootstrap";
export const NotFound = () => (
  <Container
    fluid
    style={{
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "50px",
    }}
  >
    <div style={{ maxWidth: "60vw" }}>
      <h1
        style={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        404
      </h1>
      <p style={{ textAlign: "center", fontSize: "1.2rem" }}>
        Cannot find what you are looking for
      </p>
    </div>
  </Container>
);
