import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactGA from "react-ga";

import { Title } from "./framework/Title";

import akb from "../logos/akb.png";
import ykb from "../logos/ykb.png";
import bkt from "../logos/bkt.png";
import isb from "../logos/isb.png";
import aes from "../logos/aes.png";
import trc from "../logos/trc.png";
import tkm from "../logos/tkm.png";
import ttm from "../logos/ttm.png";
import rch from "../logos/rch.png";

import as from "../logos/akilli_sistemler.jpg";
import dgr from "../logos/DGR.png";
import evam from "../logos/evam_logo.jpg";
import ibm from "../logos/IBM.jpg";
import ms from "../logos/microsoft.png";
import ah from "../logos/abexhealth.png";
import so from "../logos/system_optima.png";
import pega from "../logos/pega-new.jpg";
import qlik from "../logos/qlik-logo.png";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("references");
}

const imgStyle = {
  width: "256px",
  marginLeft: "2.5%",
  marginRight: "2.5%",
  marginBottom: "5vh",
};

function References() {
  initGA();
  return (
    <Container
      fluid
      style={{
        marginTop: "100px",
        marginBottom: "50px",
        minHeight: "100vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Title>Our Clients</Title>
      <br />
      <Row style={{ textAlign: "center" }}>
        <Col xs={12} style={{ marginBottom: "4%" }}>
          <h3>Finance</h3>
          <br />
          <a
            href="https://www.yapikredi.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={ykb} />
          </a>
          <a
            href="https://www.akbank.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={akb} />
          </a>
          <a
            href="https://www.isbank.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={isb} />
          </a>
          <a
            href="https://www.bkt.com.al"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={bkt} />
          </a>
        </Col>
        <Col xs={12} style={{ marginBottom: "4%" }}>
          <h3>Telecommunications</h3>
          <br />
          <a
            href="https://www.turkcell.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={trc} />
          </a>
          <a
            href="https://www.turktelekom.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={tkm} />
          </a>
          <a
            href="https://www.tunisietelecom.tn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={ttm} />
          </a>
        </Col>
        <Col xs={12}>
          <h3>Insurance and Others</h3>
          <br />
          <a
            href="https://www.aegon.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={aes} />
          </a>
          <a
            href="https://www.roche.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={rch} />
          </a>
        </Col>
      </Row>
      <br />
      <br />
      <Title>Our Partners</Title>
      <br />
      <Row style={{ textAlign: "center" }}>
        <Col xs={12} style={{ marginBottom: "4%" }}>
          <br />
          <a
            href="https://www.ibm.com/tr-tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={ibm} />
          </a>
          <a
            href="https://www.microsoft.com/tr-tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={ms} />
          </a>
          <a href="https://evam.com/" target="_blank" rel="noopener noreferrer">
            <Image style={imgStyle} src={evam} />
          </a>
          <a
            href="https://www.pega.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={pega} />
          </a>
        </Col>
        <Col xs={12} style={{ marginBottom: "4%" }}>
          <br />
          <a
            href="https://dgrproject.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={dgr} />
          </a>
          <a
            href="http://www.akillisistemler.com.tr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={as} />
          </a>
          <a
            href="https://systemoptima.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={so} />
          </a>
          <a
            href="http://www.bitechnology.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={qlik} />
          </a>
        </Col>
        <Col xs={12}>
          <br />
          <Image style={imgStyle} src={ah} />
        </Col>
      </Row>
    </Container>
  );
}

export default References;
