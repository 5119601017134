import React from "react";
import { Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";

import { Card, SpaceHalf } from "./framework/Card";
import { Title } from "./framework/Title";

import BPM from "../image/Solutions/EnterpriseSoftwareSolutions/BPM.jpg";
import enterprice from "../image/Solutions/EnterpriseSoftwareSolutions/EnterpriseSoftwareDevelopment.jpg";
import programming from "../image/Solutions/EnterpriseSoftwareSolutions/ProgrammingLanguageTranslationSoftware.jpg";
import mainframe from "../image/Solutions/EnterpriseSoftwareSolutions/mainframe.jpg";
import RPA from "../image/Solutions/EnterpriseSoftwareSolutions/RPA.jpg";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("enterprise_software_solutions");
}

function ESS() {
  initGA();
  return (
    <Container
      fluid
      style={{
        marginTop: "100px",
        marginBottom: "50px",
        minHeight: "100vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Title>Enterprise Software Solutions</Title>
      <br />
      <Row fluid>
        <Card title="Enterprise Software Development" src={enterprice}>
          Our senior developers are experts on ASP.net, Java, PL/SQL, Pega, C,
          C#, VBA, JavaScript, TypeScript, Node-Red, React JS. This allows us to
          finish projects successfully and to a professional standard.
          <br />
          <br />
          <br />
        </Card>
        <Card
          title="Programming Language Translation Software"
          src={programming}
        >
          Our senior developers have created a software that can translate the
          following programming languages from one to another. By using their
          expertise we are capable of providing language translation techniques
          and end-to-end solutions.
        </Card>
        <Card title="Business Process Management Tools (BPM - Pega)" src={BPM}>
          Our Pega certified experts are capable of developing banking ands
          insurance solutions .
          <br />
          <br />
          <br />
          <br />
        </Card>
        <SpaceHalf />
        <Card
          title="Mainframe Migration and Conversion Applications"
          src={mainframe}
        >
          Our experts are proficient in conversion and migration methods. By
          using their expertise we are capable of providing mainframe conversion
          and migration techniques and end-to-end solutions.
        </Card>
        <Card title="Test Automation (RPA - Selenium)" src={RPA}>
          The experience of our test experts allows us to develop automated test
          scenarios via using open source Selenium tool
          <br /> <br /> <br />
        </Card>
        <SpaceHalf />
      </Row>
    </Container>
  );
}

export default ESS;
