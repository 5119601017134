import React from "react";
import { Row, Col } from "react-bootstrap";

export const Title = (props) => (
  <Row>
    <Col xs={0} md={3} />
    <Col xs={12} md={6}>
      <h2
        style={{
          textAlign: "center",
          padding: "5px",
          borderBottom: "2px solid #c40000",
        }}
      >
        {props.children}
      </h2>
      <br />
    </Col>
    <Col xs={0} md={3} />
  </Row>
);
