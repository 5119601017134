import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import akb from "../../../logos/akb.png";
import ykb from "../../../logos/ykb.png";
import bkt from "../../../logos/bkt.png";
import isb from "../../../logos/isb.png";
import aes from "../../../logos/aes.png";
import trc from "../../../logos/trc.png";
import tkm from "../../../logos/tkm.png";
import ttm from "../../../logos/ttm.png";
import rch from "../../../logos/rch.png";

const imgStyle = {
  width: "256px",
  marginLeft: "2.5%",
  marginRight: "2.5%",
  marginBottom: "5vh",
};

export const References = () => (
  <div>
    <Container fluid style={{ textAlign: "center", marginTop: "5vh" }}>
      <br />
      <h2>Our Clients</h2>
      <br />
      <Row>
        <Col xs={12} style={{ marginBottom: "4%" }}>
          <h3>Finance</h3>
          <br />
          <a
            href="https://www.yapikredi.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={ykb} />
          </a>
          <a
            href="https://www.akbank.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={akb} />
          </a>
          <a
            href="https://www.isbank.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={isb} />
          </a>
          <a
            href="https://www.bkt.com.al"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={bkt} />
          </a>
        </Col>
        <Col xs={12} style={{ marginBottom: "4%" }}>
          <h3>Telecommunications</h3>
          <br />
          <a
            href="https://www.turkcell.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={trc} />
          </a>
          <a
            href="https://www.turktelekom.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={tkm} />
          </a>
          <a
            href="https://www.tunisietelecom.tn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={ttm} />
          </a>
        </Col>
        <Col xs={12}>
          <h3>Insurance and Others</h3>
          <br />
          <a
            href="https://www.aegon.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={aes} />
          </a>
          <a
            href="https://www.roche.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image style={imgStyle} src={rch} />
          </a>
        </Col>
      </Row>
      <br />
    </Container>
  </div>
);
