import React from "react";
import { Container } from "react-bootstrap";
import ReactGA from "react-ga";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("about");
}

function About() {
  initGA();
  return (
    <Container
      fluid
      style={{
        minHeight: "75vh",
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
        marginTop: "25vh",
        marginBottom: "50px",
      }}
    >
      <div style={{ maxWidth: "80vw" }}>
        <h1
          style={{
            textAlign: "center",
            padding: "5px",
            borderBottom: "2px solid #c40000",
          }}
        >
          About BE1 Consultancy
        </h1>
        <p style={{ fontSize: "1.2rem" }}>
          We are a leading research – development/consulting company that has
          been working with a variety of customers since 2013. Our team consists
          of information technologies and services professionals, who have over
          25 years of experience in the industry. Our aim is to prioritise the
          demands and needs of our customers and provide the best solution-based
          services.
          <br />
          <br />
          We provide solutions in various projects from different industries,
          ranging from banking to manufacturing. The experience of our team
          allows us to undertake complicated tasks such as business consulting,
          architectural design, software development, project management, system
          testing and administration.
          <br />
          <br />
          As BE1 Consultancy, we always produce comprehensive software solutions
          and excellent consultancy services with our solution-oriented service
          approach. By forming a joint project team together with customers and
          solution partners, we pinpoint the exact needs of our customers and
          provide crucial assistance in turn-key projects.
        </p>
      </div>
    </Container>
  );
}

export default About;
